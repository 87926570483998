import Image from 'next/legacy/image'
import Head from 'next/head'
import styles from '@styles/404.module.scss'
import Link from 'next/link'

// Custom 404 page
export default function Custom404() {
    return <>
        <Head>
            <title>Crikey! We{'\''}re down for scheduled maintenance!</title>
        </Head>
        <section className={styles.four04}>
            <header>
                <h1>
                    <span>Crikey!</span>
                    <span className="gibson">404 - page not found</span>
                </h1>
                <p>Unfortunately we couldn{'\''}t find this page.
                    <br />
                    <Link href="/" className={styles.link}>
                        Click Here
                    </Link>
                    {' '} to return to the home page</p>
            </header>
            <Image
                priority
                src={`/images/crikey-404.png`}
                width={400}
                height={604}
                alt="Steve Irwin - The Crocodile Hunder"
            />
        </section>
    </>;
}